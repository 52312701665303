<template>
    <v-layout justify-center>
        <v-flex xs12 sm8 md4>
            <v-stepper v-model="stepNo">
                <v-stepper-header>
                    <template v-for="(title, index) in titles">
                        <v-stepper-step :complete="stepNo > index + 1" :step="index + 1" :key="'step'+index">{{title}}</v-stepper-step>
                        <v-divider v-if="index !== titles.length - 1" :key="'devider'+index"></v-divider>
                    </template>
                </v-stepper-header>
                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-form ref="form" v-model="valid">
                            <v-text-field outline clearable
                                prepend-icon="person"
                                name="name"
                                :label="$t('register.show-name')"
                                type="input"
                                v-model="name"
                                counter=10
                                :rules="nameRules" 
                            ></v-text-field>  
                            <v-text-field
                                outline clearable
                                prepend-icon="smartphone"
                                name="phone"
                                :label="$t('register.phone-number')"
                                type="input"
                                v-model="phone"
                                counter=11
                                :hint="$t('register.phone-hint')"
                                mask="###########"
                                :rules="phoneRules" 
                                :error="!!errors.phone"
                                :error-messages="$t(errors.phone)"
                            >
                                <template v-slot:prepend-inner>
                                    <vue-tel-input 
                                        @country-changed="countrySelected" 
                                        :preferredCountries="preferredCountries"
                                        placeholder=""
                                    >
                                        <template v-slot:arrow-icon>
                                            <strong>+{{countryCode}}</strong>
                                        </template>
                                    </vue-tel-input>
                                </template>
                            </v-text-field>
                            <v-text-field v-show="email_flag"
                                outline clearable
                                prepend-icon="email"
                                name="email"
                                :label="(countryCode === '886') ? $t('register.email-optional') : $t('register.email-required')"
                                type="email"
                                v-model="email"
                                :rules="emailRules"
                                :error="!!errors.email"
                                :error-messages="$t(errors.email)"
                            ></v-text-field>                                                                    
                            <v-text-field outline clearable
                                v-model="password"
                                prepend-icon="lock"
                                name="password"
                                :label="$t('register.password')"
                                :hint="$t('register.pass-hint')"
                                counter=20
                                :append-icon="showPass ? 'visibility_off' : 'visibility'"
                                :type="showPass ? 'input' : 'password'"
                                :rules="passwordRules" 
                                @click:append="showPass = !showPass"
                            ></v-text-field>
                        </v-form>
                        <v-btn large block class="mt-0" color="primary" @click="submit" data-cy="registerSubmitBtn">{{ $t('register.next') }}</v-btn>
                    </v-stepper-content>
                    <v-stepper-content step="2" class="pa-1">
                        <confirm-auth :showForeignPhone="showForeignPhone" :phone="phone" :email="email" :userId="userId" @pass="passAuth" @back="setStepNo(1)"></confirm-auth>
                    </v-stepper-content>
                </v-stepper-items>
                <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
                <message-dialog
                    :show.sync="msgDialog.show"
                    :msg="msgDialog.msg"
                    :primary-btn="msgDialog.primaryBtn"
                ></message-dialog>
            </v-stepper>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import ConfirmAuth from '@/components/ConfirmAuth'
import WaitingDialog from '@/components/WaitingDialog.vue'
import WaitDialogControl from '@/mixins/WaitDialogControl.vue'
import MessageDialog from '@/components/MessageDialog.vue'
import MessageDialogControl from '@/mixins/MessageDialogControl.vue'
import { getAxiosConfig, encrypt, PWA_TOKEN } from '@/utils/AuthService'
import { HOMETYPES } from '@/utils/utils'
import { HostUrl, SideBtnType } from '@/store'
import i18n from '@/i18n'
import { VueTelInput } from 'vue-tel-input'

export default {
    name: 'Register',
    components: { ConfirmAuth, WaitingDialog, MessageDialog, VueTelInput },
    mixins: [ WaitDialogControl, MessageDialogControl ],
    data() {
        return {
            titles: ['register.register', 'register.identity-verification'],
            stepNo: 1,
            valid: false,
            email_flag: (store.getters.pageHome !== HOMETYPES.WASH && store.getters.pageHome !== HOMETYPES.TRAVELDOCS),
            userId: 0,
            name: '',
            phone: '',
            countryCode: '886',
            showForeignPhone: true,
            preferredCountries: ['TW', 'CN', 'JP', 'HK', 'MO', 'KR', 'US', 'MY', 'PH', 'SG', 'VN', 'TH'],
            showPass: false,
            password: '',
            email: '',
            nameRules: [
                v => !!v || i18n.t('register.rules.nameRules.emptyErr'),
                v => (v && v.length <= 10) || i18n.t('register.rules.nameRules.maxLengthErr')
            ],
            phoneRules: [
                v => (!v || v.length >= 8) || i18n.t('register.rules.phoneRules'),
                v => {
                    if ( /^09/.test(v) || /^9/.test(v) ) {
                        if ( /^09\d{8}$/.test(v) && v.length === 10 ) {
                            return true
                        } else if ( /^9\d{8}$/.test(v) && v.length === 9 ) {
                            return true
                        } else {
                            return i18n.t('register.rules.phoneRules')
                        }    
                    } else {
                        return true
                    }
                }
                // v => (!v || /09\d{8}/.test(v)) || i18n.t('register.rules.phoneRules')
            ],
            passwordRules: [
                v => !!v || i18n.t('register.rules.passwordRules.emptyErr'),
                v => (v && v.length >= 6) || i18n.t('register.rules.passwordRules.minLengthErr'),
                v => (v && v.length <= 20) || i18n.t('register.rules.passwordRules.maxLengthErr')
            ],
            emailRules: [
               v => (!v || /.+@.+/.test(v)) || i18n.t('register.rules.emailRules')
            ],
            errors: {
                phone: null,
                email: null
            }
        }
    },
    watch: {
        phone() {
            this.errors.phone = null
            this.errors.email = null
        },
        email() {
            this.errors.phone = null
            this.errors.email = null
        },
        countryCode() {
            this.errors.phone = null
        }
    },
    methods: {
        init() {
            this.stepNo = 1
            this.userId = 0
            this.showPass = false
            this.errors = { phone: null, email: null }
        },
        passAuth() {
            store.dispatch('afterLogin')
        },
        setStepNo(no) {
            this.stepNo = no
            store.commit('setTitle', this.titles[this.stepNo - 1])
            if (no === 2) {
                store.commit('setSideBtnType', SideBtnType.Hide);
            } else {
                store.commit('setSideBtnType', SideBtnType.Back);
            }
            this.closeDialog()
        },
        validate() {
            if (this.countryCode === '886') {
                if (!this.phone && !this.email) {
                    console.log('886 兩者都沒填')
                    let phoneMsg = 'register.phone-error-messages'
                    this.errors.phone = phoneMsg
                    return false
                } 
                else if (this.phone) {
                    if (/^09\d{8}$/.test(this.phone) || /^9\d{8}$/.test(this.phone) ) {
                        return true
                    } else {
                        let msg = i18n.t('register.rules.non-Taiwan-select-country')
                        this.errors.phone = msg
                        return false
                    }
                }
                return true
            } else {
                if (!this.email) {
                    console.log('必須填寫信箱')
                    let msg = 'register.other-country-error-messages'
                    this.errors.email = msg
                    return false
                }
                return true
            }
            // if (!this.phone && !this.email) {
            //     let msg = 'register.error-messages'
            //     this.errors.phone = msg
            //     this.errors.email = msg
            //     return false
            // }
            // return true
        },
        submit() {
            console.log(this.phone)
            console.log(this.countryCode)
            if (this.$refs.form.validate() && this.validate()) {
                console.log('進來後端傳送')
                console.log(this.phone)
                console.log(this.countryCode)
                this.showDialog(i18n.t('register.send-register-info'), i18n.t('register.send-fail'))
                if (/^9\d{8}$/.test(this.phone)) { 
                    this.phone = '0' + this.phone 
                }
                if (this.phone[0] == '0' && this.phone[1] == '9' && this.phone.length == 10) {
                    this.country_code = '886'
                    console.log('revise:'+this.countryCode)
                }
                //const timestamp = Date.now();
                //localStorage.setItem("RStamp", timestamp);
                var self = this
                let payload = {
                    country_code: self.phone ? self.countryCode : '',
                    // country_code: this.phone ? '886' : '',
                    phone: self.phone || '',
                    password: encrypt(self.password),
                    name: self.name,
                    email: self.email || '',
                    browser: navigator.userAgent
                }
                axios.post(HostUrl + '/api/user/register', payload, getAxiosConfig(PWA_TOKEN))
                    .then(function(response) {
                        if (response.data.code == 0) {
                            self.userId = response.data.auth.user_id
                            self.setStepNo(2)
                        } else {
                            self.userId = 0
                            if (response.data.message === '手機號碼輸入錯誤') {
                                self.showMsgDialog(i18n.t('register.wrong-phone'), i18n.t('register.close'))
                            }
                            else if (response.data.message === '輸入簡訊認證碼，點選登入') {
                                self.showMsgDialog(i18n.t('register.phone-verify-again'), i18n.t('register.close'))
                                self.$router.push(`/${i18n.locale}/login`)
                            }
                            else if (response.data.message === '此email已註冊過，請直接登入') {
                                self.showMsgDialog(i18n.t('register.email-registered'), i18n.t('register.close'))
                                self.$router.push(`/${i18n.locale}/login`)
                            }
                            else if (response.data.message === '此手機號碼已註冊過、請直接登入') {
                                self.showMsgDialog(i18n.t('register.mobile-registered'), i18n.t('register.close'))
                                self.$router.push(`/${i18n.locale}/login`)
                            } else {
                                self.showMsgDialog(response.data.message, i18n.t('register.close'))
                            }
                        }
                    })
                    .catch(function(error) {
                        console.error('Register API failed', error)
                        self.showMsgDialog(i18n.t('register.registration-fail'), i18n.t('register.close'))
                    })
                    .finally(function() { self.closeDialog() })
            } else {
                this.showMsgDialog(i18n.t('login.login-fail-message'), i18n.t('login.confirm'))
            }
        },
        countrySelected(val) {
            this.countryCode = val.dialCode;
            if (this.countryCode !== '886') {
                this.showForeignPhone = false
            } else {
                this.showForeignPhone = true
            }
        }
    },
    activated() {
        this.init()
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
        store.commit('setTitle', this.titles[0]);
        store.commit('setSideBtnType', SideBtnType.Back);
    },
    deactivated() {
        store.commit('setSideBtnType', SideBtnType.Navi);
    }
};
</script>

<style scoped>
    .vti__input{
    display: none;
    }

    .vue-tel-input{
    width: 70px;
    height: 30px;
    }
</style>
